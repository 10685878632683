.footer {
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--gray);
	height: fit-content;
	width: 100%;
	padding-top: 50px;
}

.footer .container {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: flex-start;
	width: 90vw;
	height: 100%;
}

.footer .container .left {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: calc(90vw / 2);
	height: 100%;
}

.footer .container .left h1 {
	display: flex;
	font-size: 34px;
	text-align: center;
	font-family: var(--bold);
	letter-spacing: 1px;
	color: var(--light);
	cursor: pointer;
	margin: 0;
	transition: all 0.5s ease;
	width: calc(90vw / 2);
}

.footer .container .left h1:hover {
	transform: scale(1.05);
}

.footer .container .left .contact-boxes {
	width: calc(90vw / 2);
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-top: 30px;
}

.footer .container .left .contact {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 2px solid var(--grey);
	width: 100%;
	margin: 20px 10px;
	cursor: pointer;
}

.footer .container .left .contact::before,
.footer .container .left .contact::after {
	content: '';
	position: absolute;
	border-radius: inherit;
	border: inherit;
	transition: all 0.5s;
	width: 50px;
	height: 30px;
}

.footer .container .left .contact::before {
	top: -10px;
	left: -10px;
	border-width: 2px 0 0 2px;
}

.footer .container .left .contact::after {
	bottom: -10px;
	right: -10px;
	border-width: 0 2px 2px 0;
}

.footer .container .left .contact:hover::before,
.footer .container .left .contact:hover::after {
	width: calc(100% + 18px);
	height: calc(100% + 18px);
}

.footer .container .left .contact p {
	font-size: 14px;
	font-family: var(--regular);
	letter-spacing: 1px;
	margin-top: 30px;
	margin-bottom: 15px;
}

.footer .container .left .contact h6 {
	font-size: 18px;
	font-family: var(--regular);
	font-weight: lighter;
	color: var(--light);
	letter-spacing: 1px;
	margin-top: 15px;
	margin-bottom: 30px;
}

.footer .container .right {
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	height: 100%;
	width: calc(90vw / 2);
}

.footer .container .right .tabs {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	padding-left: 100px;
}

.footer .container .right .tabs h3 {
	margin-bottom: 20px;
	font-family: var(--regular);
	color: var(--light);
	letter-spacing: 1px;
	text-transform: uppercase;
}

.footer .container .right .tabs ul {
	list-style: none;
	padding: 0;
}

.footer .container .right .tabs ul li {
	margin: 20px 0;
	transition: all 0.5s ease;
	text-decoration: none;
	font-family: var(--regular);
	letter-spacing: 1px;
	color: var(--light);
}

.footer .container .right .tabs ul li:hover {
	transform: scale(1.05);
	cursor: pointer;
}

.footer .footer-bottom {
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 2px solid var(--grey);
	margin-top: 50px;
	width: 100%;
	padding: 20px 0;
}

.footer .footer-bottom p {
	font-family: var(--regular);
	letter-spacing: 1px;
	text-align: center;
	color: var(--light);
	width: 100%;
}

.footer .footer-bottom span {
	letter-spacing: 1px;
	text-align: center;
	color: var(--light);
	cursor: pointer;
}

.footer .footer-bottom p span:hover {
	color: var(--yellow);
}

@media screen and (max-width: 1200px) {
	.footer .container .left .contact-boxes {
		width: calc(90vw / 2);
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		margin-top: 30px;
	}

	.footer .container .left .contact {
		width: 100%;
		margin: 10px 5px;
	}

	.footer .container .left .contact p {
		margin-top: 15px;
		margin-bottom: 10px;
	}

	.footer .container .left .contact h6 {
		font-size: 16px;
		margin-top: 10px;
		margin-bottom: 15px;
	}
}

@media screen and (max-width: 992px) {
	.footer .container {
		display: flex;
		position: relative;
		justify-content: space-between;
		align-items: flex-start;
		width: 90vw;
		height: 100%;
	}

	.footer .container .left .contact-boxes {
		width: calc(90vw / 2);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
	}

	.footer .container .left h1 {
		font-size: 26px;
		text-align: left;
		width: calc(90vw / 2);
		margin-top: 15px;
	}

	.footer .container .left {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: calc(90vw / 2);
		height: 100%;
	}

	.footer .container .right {
		display: flex;
		justify-content: space-evenly;
		align-items: flex-start;
		height: 100%;
		width: calc(90vw / 2);
	}

	.footer .container .right .tabs {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		padding-left: 50px;
	}
}

@media screen and (max-width: 768px) {
	.footer .container {
		display: flex;
		position: relative;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 90vw;
		height: 100%;
	}

	.footer .container .left .contact-boxes {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
	}

	.footer .container .left {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	.footer .container .left h1 {
		font-size: 20px;
		text-align: center !important;
		width: 100%;
		margin-top: 15px;
	}

	.footer .container .right {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		height: 100%;
		width: 100%;
		margin-top: 50px;
	}

	.footer .container .right .tabs {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		padding-left: 10px;
	}

	.footer .footer-bottom p {
		width: 90%;
	}
}

@media screen and (max-width: 480px) {
	.footer .container .left {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}

	.footer .container .left h1 {
		font-size: 18px;
	}

	.footer .container .right {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		height: 100%;
		width: 100%;
		margin-top: 50px;
	}

	.footer .container .right .tabs {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		width: 100%;
		padding-left: 10px;
	}

	.footer .container .right .tabs ul {
		list-style: none;
		padding: 0;
		text-align: center;
		width: 100%;
	}

	.footer .container .right .tabs ul li {
		margin: 20px 0;
		transition: all 0.5s ease;
		text-decoration: none;
		font-family: var(--regular);
		letter-spacing: 1px;
	}

	.footer .footer-bottom p {
		width: 90%;
	}
}
