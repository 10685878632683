.service-card-container {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--dark);
	width: 20vw;
	height: 25vw;
	padding: 10px;
	margin: 10px;
	transition: all 0.5s ease;
	border: 2px solid var(--yellow);
	cursor: pointer;
}

.service-card-container::before,
.service-card-container::after {
	content: '';
	position: absolute;
	border-radius: inherit;
	border: inherit;
	transition: all 0.5s;
	height: 20px;
	width: 20px;
}

.service-card-container::before {
	top: -10px;
	left: -10px;
	border-width: 2px 0 0 2px;
}

.service-card-container::after {
	bottom: -10px;
	right: -10px;
	border-width: 0 2px 2px 0;
}

.service-card-container:hover::before,
.service-card-container:hover::after {
	width: calc(100% + 18px);
	height: calc(100% + 18px);
}

.service-card-container .icon {
	width: 90px;
	height: 90px;
	overflow: hidden;
}

.service-card-container .image {
	object-fit: cover;
	width: 100%;
	width: 100%;
}

.service-card-container .heading {
	font-size: 20px;
	font-family: 'Urbanist-Semibold';
	text-align: center;
	margin-bottom: 0px;
	letter-spacing: 1px;
}

.service-card-container .paragraph {
	font-size: 16px;
	text-align: center;
	margin-bottom: 0px;
	font-family: var(--regular);
	letter-spacing: 1px;
	line-height: 1.5;
}

@media screen and (max-width: 1200px) {
	.service-card-container {
		width: 20vw;
		height: 30vw;
	}

	.service-card-container .icon {
		width: 60px;
		height: 60px;
		overflow: hidden;
	}
}

@media screen and (max-width: 992px) {
	.service-card-container {
		width: 30vw;
		height: 35vw;
	}

	.service-card-container .icon {
		width: 100px;
		height: 100px;
		overflow: hidden;
	}
}

@media screen and (max-width: 768px) {
	.service-card-container {
		width: 40vw;
		height: 45vw;
		margin: 8px;
	}

	.service-card-container .icon {
		width: 70px;
		height: 70px;
		overflow: hidden;
	}
}

@media screen and (max-width: 480px) {
	.service-card-container {
		width: 85vw;
		height: 90vw;
		margin: 10px 5px;
	}

	.service-card-container .icon {
		width: 90px;
		height: 90px;
		overflow: hidden;
	}
}

@media screen and (max-width: 320px) {
	.service-card-container {
		width: 90vw;
		height: 90vw;
		padding: 5px;
		margin: 10px 5px;
	}

	.service-card-container .icon {
		width: 70px;
		height: 70px;
		overflow: hidden;
	}
}
